<template>
  <div
    class="invoice-header flex items-center justify-between py-2 z-20 border-t-8 print:border-t-0 border-gray-500 text-sm relative"
  >
    <div
      class="order_no py-1 px-3 text-sm bg-gray-500 absolute top-0 left-1/1 -translate-x-1/1 text-white font-bold transform skew-x-12"
    >
      {{ orderInfo.order_no }}
    </div>
    <div class="container flex items-center justify-between">
      <div class="order-main-info pt-4">
        <ul class="grid grid-cols-1 gap-x-8 gap-y-1 text-sm">
          <li>
            <span class="font-bold"> وقت الدخول : </span>
            <span>
              {{ getFormDate(orderInfo.create_at, "DD/MM/YYYY aa:mm") }}
            </span>
          </li>
          <li>
            <span class="font-bold"> وقت الخروج : </span>
            <span>
              {{ getFormDate(orderInfo.updated_at) }}
            </span>
          </li>
        </ul>
      </div>
      <div class="logo">
        <img src="@/assets/logo_empty.png" class="rounded-md w-36" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    orderInfo: Object,
  },
};
</script>
<style scoped>
.invoice-header {
  border-bottom: 2px solid #efefef;
}
</style>
