import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import { mapGetters ,mapActions , mapMutations } from 'vuex'
import _get_loader from "./components/Globle/_get_loader.vue"
import VueQrcode from '@chenfengyuan/vue-qrcode';
import moment from "moment"
import 'hint.css'
import '@/assets/styles/style.css'

var mixin = {
    data(){
        return{
            baseUrl:'http://162.214.159.53:8020/api/',
            alertMsg:"You won't be able to revert this!"
        }
    },
    computed:{
        ...mapGetters([
            'getLoader',
            'order',

        ]),
    },
    methods: {
        ...mapMutations(['toggleEditStatus','toggleSideBarStatus']),
        ...mapActions([
            'getPublicOrder',
        ]),
        orderStatus(status){
            const statuses={
            0:{
                text:'Pending',
                cla:'pending'
            },
            1:{
                text:'Jek',
                cla:'jek'
            },
            2:{
                text:'Daino',
                cla:'daino'
            },
            3:{
                text:'Cover',
                cla:'cover'
            },
            4:{
                text:'Completed',
                cla:'completed'
            },
            5:{
                text:'Cancelled',
                cla:'cancelled'
            },
            6:{
                text:'Computer',
                cla:'comp'
            },
        }
            return statuses[status]
        },
        coverStatus(status){
            const statuses={
            0:{
                text:'سليم',
            },
            1:{
                text:'مستبدل بلادي',
            },
            2:{
                text:'القطعة مفقودة',
            },
            3:{
                text:'مستبدل مصبوغ',
            },
            4:{
                text:'بارد',
            },
            5:{
                text:'قطعة مصبوغة',
            },
            6:{
                text:'معجون',
            },
            7:{
                text:'تكحيل',
            },
            8:{
                text:'كلير',
            },
            9:{
                text:'شخوط',
            },
            10:{
                text:'رصعة',
            },
            11:{
                text:'جراد',
            },
        }
            return statuses[status]
        },
        getFormDate(value){
            return moment(value).format('DD/MM/YYYY HH:MM a')
        },
    },
}
Vue.component('get-loader',_get_loader)
Vue.component(VueQrcode.name, VueQrcode);
Vue.mixin(mixin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
