<template>
  <div id="home_page"></div>
</template>
<script>
export default {
  mounted() {
    this.$router.push("/error/error_page");
  },
};
</script>
