import Vue from 'vue'
import Vuex from 'vuex'
import orders from "./modules/Orders.js"

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    loadingData:false,
    getLoader:false,
    postLoader:false,


  },
  getters:{
    getLoader: state => state.getLoader,
    postLoader: state => state.postLoader,
    
  },
  mutations: {
    toggleEditStatus(state) {
      state.editStatus = !state.editStatus
    },
    toggleSideBarStatus(state) {
      state.sideBarStatus = !state.sideBarStatus
    },
  },
  modules: {
    orders,
  }
})
