import $http from "@/plugins/axios.js"

export default {
    state:{
        orders:[],
        order:{
            cover:{},
            daino:{},
            jek:{},
            images:[],
            computer:[],
        },
        order_imgs:[]
    },
    getters:{
        orders: state => state.orders,
        order: state => state.order
    },
    actions:{ 
        async getOrders({commit} , filterData = {take:15,page:1,name:'',chassis_no:'',sanoia_no:'',}){
            const response = await $http.get(`/order?take=${filterData.take}&page=${filterData.page}&filter[driver_name]=${filterData.name}&filter[chassis_no]=${filterData.chassis_no}&filter[sanoia_no]=${filterData.sanoia_no}`)
            commit("SET_ORDERS", response.data);
        },
        async getDayleOrders({commit} , filterData = {take:15,page:1,name:'',chassis_no:'',sanoia_no:'',}){
            const response = await $http.get(`/orders-today?take=${filterData.take}&page=${filterData.page}&filter[driver_name]=${filterData.name}&filter[chassis_no]=${filterData.chassis_no}&filter[sanoia_no]=${filterData.sanoia_no}`)
            commit("SET_ORDERS", response.data);
        },

        async getOrder({commit} , data){
            const response = await $http.get(`/order/${data.id}?include=${data.include}`) //jek,daino,cover,images
            commit("SET_ORDER", response.data);
        },
        async getPublicOrder({commit} , id){
            const response = await $http.get(`/show/crc/get-order/${id}`) //jek,daino,cover,images
            commit("SET_ORDER", response.data);
        },

        async getCoverOrders({commit} , filterData = {number:'',}){
            const response = await $http.get(`/order-cover?filter[order_no]=${filterData.number}`)
            commit("SET_ORDERS", response.data);
        },

        async getCompletedOrders({commit} , filterData = {number:'',}){
            const response = await $http.get(`/order-completed?filter[order_no]=${filterData.number}`)
            commit("SET_ORDERS", response.data);
        },

        async getCancelleddOrders({commit} , filterData = {number:'',}){
            const response = await $http.get(`/order-cancelled?filter[order_no]=${filterData.number}`)
            commit("SET_ORDERS", response.data);
        },

        async createOrder({commit},order){
            const response = await $http.post('/order',order)
            commit('ADD_NEW_ORDER',response.data.data)
            return response.data
        },
 
        async getOrderImage({commit},id){
            await $http.get(`/order-image/${id}`)
            return commit
        },
        async createOrderImage({commit},order){
            await $http.post('/order-image',order)
            return commit
        },

        async updateOrder({commit} , order){
            const response = await $http.post(`/order/${order.id}`,order)
            commit('UPDATE_ORDER',response.data.data)
        },
        async removeOrder({commit} , id){
            await $http.delete(`/order/${id}`)
            commit('REMOVE_ORDER',id)
        },
        async completeOrder({commit} , id){
            const response = await $http.put(`/order-complete/${id}`)
            commit('UPDATE_ORDER',response.data.data)
        },
        async canceleOrder({commit} , id){
            const response = await $http.put(`/order-cancelled/${id}`)
            commit('UPDATE_ORDER',response.data.data)
        },
        
        async changeStatusOrder({commit} , data){
            await $http.post(`/order-status/${data.order_id}`,data.data)
            return commit
        },
        async uploadImage({commit} , data){
            await $http.post(`/order-image/${data.id}`,data.image)
            return commit
        },
        async removeImage({commit} , id){
            await $http.delete(`/order-image/${id}`)
            commit('REMOVE_ORDER_ORDER',id)
        },

    },
    mutations:{
        SET_ORDERS(state,data){
            state.orders = data
        },
        SET_ORDER(state,data){
            state.order = data
        },
        ADD_NEW_ORDER(state,data){
            state.orders.data.push(data)
        },
        UPDATE_ORDER(state,data){
            state.orders.data  = state.orders.data.map(item => item = item.id == data.id ? data : item)
        },
        REMOVE_ORDER(state,id){
            state.orders.data  = state.orders.data.filter(item=> item.id !== id)
        },
        REMOVE_ORDER_ORDER(state,id){
            state.order.images  = state.order.images.filter(item=> item.id !== id)
        },
        
    }
}