import Vue from 'vue'
import VueRouter from 'vue-router'

import index from '../views/indexPage.vue'
import home from '../views/homePage.vue'
import error_page from '../views/404Page.vue'

Vue.use(VueRouter)
const routes = [
    {
    path: '/',
    name: 'home',
    component: home
  },
    {
    path: '/:id',
    name: 'index',
    component: index
  },
    {
    path: '/error/error_page',
    name: 'error_page',
    component: error_page
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
