import axios from "axios";
import store from "@/store/index.js"
import Swal from 'sweetalert2'

axios.interceptors.request.use(config => {
    // config.baseURL = "http://52.91.152.107:9000/api",
    // config.baseURL = "http://3.88.222.103:9080/api",
    config.baseURL = "https://back.crc-almandelawi.com/api",
    // config.baseURL = "https://testv1.crc-almandelawi.com/api",
    // conf ig.headers.Authorization = `Base ${ localStorage.getItem('il_user_Info') !== null ? JSON.parse(localStorage.getItem('il_user_Info')).auth : ''}`
    config.method == "get" ? store.state.getLoader = true : store.state.postLoader = true
    config.headers = {
        'Access-Control-Allow-Origin':'*',
        'Authorization': `Bearer ${ localStorage.getItem('car_user_Info') !== null ? JSON.parse(localStorage.getItem('car_user_Info'))?.token : ''}`
    }
    return config;

})

axios.interceptors.response.use(response => {
    response.config.method == "get" ? store.state.getLoader = false : store.state.postLoader = false
    if(response.config.method !== 'get'){
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.data.message ,
            showConfirmButton: false,
            timer: 1500
        })
    }
    return response
}, (error)=>{
    if(error.response.status == 401){
        localStorage.removeItem("car_user_Info");
        window.location.reload()
    }

    store.state.getLoader = false 
    store.state.postLoader = false 


    if(error.response.status == 400){

        var setError = "";
          for (var key in error.response.data) {
            setError += `-${error.response.data[key][0]} \n`;
          }

    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        showConfirmButton: false,
        text: setError,
        footer: ''
    })
    
    }else{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            showConfirmButton: false,
            text: error.response.data.message,
            footer: ''
        })  
    }

    return Promise.reject(error);

    }
)

export default axios ; 